.my-element {
  background-image: url("../../img/banner.jpg");
  background-size: cover;
  background-position: center;
  height: max-content;
  background-repeat: no-repeat;
  object-fit: cover;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
}
.my-elements {
  background-image: url("../../img/bg.jpg");
  background-size: cover;
  background-position: center;
  height: max-content;
  background-repeat: no-repeat;
  object-fit: cover;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
}
.my-elementTwo {
  background-image: url("../../img/bannerTwo.jpg");
  background-size: cover;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  object-fit: cover;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  /* height: fit-content; */
}
.my-elementThree {
  background-image: url("../../img/shadow.jpg");
  background-size: cover;
  /* background-position: center; */
  /* width: auto; */
  /* height: max-content; */
  background-repeat: no-repeat;
  object-fit: cover;
}
.my-elementfour {
  background-image: url("../../img/dock-dine-bg.jpg");
  background-size: cover;
  background-position: center;
  width: auto;
  height: max-content;
  background-repeat: no-repeat;
  object-fit: cover;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: #f0f0f0;
  padding: 20px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 10px 0;
  cursor: pointer;
}
.activeone {
  color: #88001b;
  text-decoration: underline;
}
.active {
  background-color: #88001b !important;
  color: white !important;
}
.activeOne {
  background-color: black !important;
  color: white !important;
}
.mapImage {
  background-image: url("../../img/map.png");
  background-repeat: no-repeat;
}
* {
  margin: 0;
  padding: 0;
}
.rate {
  float: left;
  height: 46px;
  padding: 0 10px;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}
.rate:not(:checked) > label:before {
  content: "★ ";
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}
.indicator {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border: 1px #f20909 solid;
  margin: 0;
}

.indicator.active {
  color: #fff;
  background: #cb0d0d;
}
.slide-container {
  width: 100%;
  /* overflow: hidden;
  position: relative;  */
  height: auto !important;
}

.each-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; 
  background-color: #f2f2f2; /* Adjust the background color as needed */
}

.each-slide > div {
  font-size: 18px; /* Adjust the font size as needed */
  color: #333; /* Adjust the text color as needed */
}
.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-spinner {
  -moz-appearance: textfield;
}
.scrollable-list {
  max-height: 200px; /* Adjust the height according to your needs */
  overflow-y: scroll;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9 !important;
  position: absolute;
}
.capitalize {
  text-transform: capitalize;
}

.slideshow {
  position: relative;
  width: 100%;
  height: 300px; 
  overflow: hidden;
}

.slides-container {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.slideshow img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Optional: Add navigation arrows */

.next-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}

.prev-arrow {
  left: 10px;
}

.next-arrow {
  right: 10px;
}
.slick-next {
  color: rgb(241, 55, 22);
}

.slick-prev:before,
.slick-next:before {
  color: rgb(241, 55, 22);
}
/* CSS for Image Slider */
.image-slider-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 800px; /* Set the desired width for the slider */
}

.image-slider {
  display: flex;
  transition: transform 0.3s ease;
}

.slick-slide img {
  max-height: 678px;
  min-height: fit-content !important;
}
.image-slide {
  flex: 0 0 100%; /* Each slide occupies the full width of the container */
  padding: 10px;
  box-sizing: border-box;
}

.image-slide img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.slide-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.slide-button {
  cursor: pointer;
  margin: 0 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
  transition: background-color 0.2s ease;
}
.slide-button:hover {
  background-color: #f0f0f0;
}
.drop-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-item a {
  text-decoration: none;
  display: flex;
  justify-content: center;
}
.drop-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 100px;
  scroll-behavior: smooth;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.drop-menu-item {
  width: 100%;
}
.drop-menu-item:hover {
  background-color: #eee;
}

.drop-menu-item a {
  color: #555;
}
.menu-item:hover .drop-menu {
  display: block;
  z-index: 9999 !important;
  overflow-y: scroll;
  height: 400px;
  padding: 7px;
  margin-left: -66px !important;
}

li {
  margin: 0px !important;
  cursor: pointer;
}
.gap-6.px-2.flex.relative.cursor-pointer.border.py-2.shadow-lg {
  min-height: 300px;
}
.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform,transition;
  gap: 8px;
}
.react-slideshow-fadezoom-wrapper {
  overflow: hidden;
  width: 100%;
  height: auto !important;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
  z-index: 0;
}
.maparea .map.img-responsive {background-repeat: no-repeat !important;}
.forMobile { display:none;}
@media all and (max-width:500px){
    .forMobile { display:block;}
    .forDesktop { display:none;}
    .maparea.forMobile {width: 320px;margin: auto;}
}


/* Apply the class on hover */
.area-hover:hover {
  background-color: red;
}